import { FC } from "react";
import logo from "../../assets/images/logo3.svg";

const Navbar: FC = () => {
    return (
        <div className="h-16 py-2 px-1 bg-primary flex justify-center items-center lg:justify-start">
            <a href="https://gradcut.com" className="h-full object-contain">
             <img src={logo} alt="Gradcut Logo" className="h-full object-contain" />
            </a>
        </div>
    );
};

export default Navbar;