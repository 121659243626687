import React, {FC, useEffect, useState} from "react";
import {redirect, useNavigate} from "react-router-dom";
import Share from "../../components/Share";
import { CopyLink } from "../../components/CopyLink";
import type { CopyModal } from "../../components/CopyLink";
import tickIcon from "../../assets/images/Approve(Tick).svg";
import APIService from "../../network/APIService";

interface MyObject {
    title: string;
    image: string;
}

interface VideoPurchase {
    studentId: number;
    graduationId: number;
    videoFormat: string;
}

const VideoLibrary: FC = () => {
    const [shareModal, setShareModal] = useState<boolean>(false);
    const [copyModal, setCopyModal] = useState<CopyModal>({} as CopyModal);
    const [loading, setLoading] = useState<boolean>(false);
    const [cutData, setCutData] = useState<any[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [paid, setPaid] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleProceedToPayment = async () => {
        const scheduled = cutData.filter((d: any) => d.selected).map((d: any) => d.id);
        if (scheduled.length === 0) return;
        console.log("scheduled", scheduled);
        const response = await APIService.payment(scheduled).catch(console.error)
        const url = response.data.checkout_url
        console.log("redirecting to:", url);
        window.location.href = url;
    };


    useEffect(() => {
        fetchCuts();
    }, []);


    useEffect(() => {
        console.log("cutData", cutData);
        for (const cut of cutData) {
            if (!cut.preview) {
                setPaid(true);
            }
        }

    }, [cutData]);

    const fetchCuts = async () => {
        setLoading(true);
        try {
            const response = await APIService.getPreviews();
            console.log("object, response", response);
            if (response && response.status === 200) {
                setLoading(false);
                setCutData(response.data);
            } else {
                setLoading(false);
            }
            console.log(response);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const videoReady = async (video: any) => {
        video.video_ready = true;
        setCutData([...cutData]);
    }
    const downloadVideo = async (path: string, name: string) => {
        try {
            // Create a download link
            const downloadLink = document.createElement("a");
            downloadLink.href = path;
            downloadLink.download = name;
            document.body.appendChild(downloadLink);

            // Trigger the click event to start the download
            downloadLink.click();

            // Remove the link from the DOM
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error("Error downloading video:", error);
        }
    };
    return (
        <div>
            {(shareModal || copyModal.enabled) && (
                <div className="fixed inset-0 bg-black opacity-50 z-50"></div>
            )}
            <div className="flex items-center justify-center">
                {paid && (
                    <div className="bg-primary rounded-full w-5 h-5 lg:w-10 lg:h-10">
                        <img
                            src={tickIcon}
                            alt="tickIcon"
                            className="w-5 h-5 lg:w-10 lg:h-10"
                        />
                    </div>
                )}
                <h1 className="flex items-center justify-center m-4 p-2 font-bold text-base text-center text-2xl lg:text-3xl">
                    {"Your Personalised On-Stage video of your Graduation"}
                </h1>
            </div>
            {!paid && (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-20 mx-10">
                    <div className="flex flex-col items-left justify-left">

                        <h2 className="font-bold">Downloading Your Graduation Videos:</h2>
                        <p>1. Click on a video to watch the preview</p>
                        <p>2. Tick ‘I want to download my video without the watermark’</p>
                        <p>3. Select Proceed to payment</p>
                        <p>4. Once paid your video will be available for download, share, copy link</p>
                    </div>
                    <div className="flex flex-col items-left justify-left">
                        <h2 className="font-bold">Payment Options:</h2>
                        <p>Purchase a single video for $24.95.</p>
                        <p>Get a bundle deal with 3 videos in different formats for $49.95.</p>
                        <p>Perfect for sharing with friends and family on social media!</p>
                    </div>
                </div>
            )}
            <div className="my-2 mx-6 lg:my-8 lg:mx-20 p-2">
                {paid && (
                    <h3 className="flex items-center m-4 lg:mb-10 p-2 font-bold text-sm text-center lg:text-xl">
                        Preview and download your videos below
                    </h3>
                )}
                {/* Cards */}
                <div className="flex justify-center">
                    <div className="lg:w-1/2">
                        {cutData &&
                            cutData.length > 0 &&
                            cutData.map(
                                (object, index) => (
                                    // (paid ? checkedCheckboxes[index] : true) && (
                                    <div
                                        className="bg-white rounded-lg shadow-2xl grid grid-cols-1 object-top gap-0 mt-4 p-4">
                                        <div className="flex items-center justify-center"
                                             style={{display: object.video_ready ? 'none' : 'none'}}
                                        >
                                            <p className="text-center text-lg font-bold">Loading ...</p>
                                        </div>
                                        <div style={{display: object.video_ready ? 'contents' : 'contents'}}>
                                            <video controls className="shadow-lg object-cover"
                                                   onCanPlay={() => videoReady(object)}>
                                                <source src={object.full_path} type="video/mp4"/>
                                            </video>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="flex w-full my-4">
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center py-2 px-2 text-sm font-medium text-center text-gray-900 bg-white border border-black rounded-lg"
                                                    style={{opacity: object.preview ? 0.5 : 1}}
                                                    disabled={object.preview}
                                                    onClick={() => {

                                                        downloadVideo(object.full_path, object.file_name);
                                                        //   window.open(object.path, "_blank");
                                                    }}
                                                >
                                                    Download
                                                </button>
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center px-2 text-sm font-medium text-center text-gray-900 bg-white border border-black rounded-lg ml-3"
                                                    disabled={object.preview}
                                                    style={{opacity: object.preview ? 0.5 : 1}}
                                                    onClick={() => setCopyModal({
                                                        enabled: true,
                                                        text: object.full_path
                                                    })}
                                                >
                                                    Copy Link
                                                </button>
                                            </div>
                                            {/* Checkbox */}
                                            {object.preview && (
                                                <div className="flex items-center my-4">
                                                    <input
                                                        id={`checkbox-${index}`}
                                                        type="checkbox"
                                                        value=""
                                                        className="w-4 h-4 bg-gray-100 border-ash rounded focus:ring-2 accent-primary"
                                                        checked={object.selected}
                                                        onChange={() => {
                                                            object.selected = !object.selected;
                                                        }
                                                        }
                                                    />
                                                    <label
                                                        htmlFor={`checkbox-${index}`}
                                                        className="ml-2 text-sm font-medium text-black"
                                                    >
                                                        I want to Download high quality version without the watermark
                                                    </label>
                                                </div>

                                            )}
                                        </div>
                                        {object.preview && (
                                            <div className="flex items-center justify-end pb-2">
                                                <button
                                                    //   className="bg-primary text-white text-sm lg:text-base font-bold py-1 px-6 rounded-lg focus:outline-none focus:shadow-outline"
                                                    className={`text-white text-sm lg:text-base font-bold py-1 px-6 rounded-lg focus:outline-none focus:shadow-outline bg-primary`}
                                                    type="button"
                                                    onClick={handleProceedToPayment}
                                                    disabled={disabled}
                                                >
                                                    Proceed to Payment
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                )
                                // )
                            )}
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <p>If you have issues with video playback, please try using Chrome or Brave browser.</p>
            </div>

            {
                copyModal.enabled && <CopyLink setCopyModal={setCopyModal} copyModal={copyModal}/>
            }
        </div>
    )
        ;
};

export default VideoLibrary;
