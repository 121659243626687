import {FC, useState, createRef, useEffect, ChangeEvent, useCallback} from "react";
import CountdownTimer from "../DownDownTimmer/DownTownTimmer";
import CircularProgressWithLabel from "../CircularProgressWithLabel";

interface OtpProps {
    handleLogin: (otpValues: string[]) => void;
    login: () => void;
    loading: boolean;
    loginData: any;
    loginResponse: any;
}


const Otp: FC<OtpProps> = ({
                               handleLogin,
                               loading,
                               loginData,
                               loginResponse,
                               login,
                           }) => {
    const numerOfInputs = 6;

    const otpInputRefs =
        Array.from({length: numerOfInputs}, () => createRef<HTMLInputElement>());


    const [currentIndex, setCurrentIndex] = useState(0);
    const [otp, setOtp] = useState(() =>
        Array.from({length: numerOfInputs}, () => "")
    );

    const handleTimerExpire = useCallback(() => {
        console.log("Timer expired!");
        // Perform any action when the timer reaches zero
    }, []);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const {value} = e.target;
        if(value.length > 1 && index > 0) {
            return;
        }
        if(value.length > 1) {
            const otpval = value.substring(0, numerOfInputs);
            console.log("otpval", otpval);
            setOtp(otpval.split(""));
            setCurrentIndex(numerOfInputs - 1);
            return;
        }
        console.log("value", value);
        setOtp((otp) => {
                const retval = otp.map((symbol, otpIndex) =>
                    otpIndex === index ? value : symbol
                )
                console.log("retval", retval);
                return retval;
            }
        );
    }

    useEffect(() => {
        console.log("useEffect: otp", otp);
        console.log("inputRefs", otpInputRefs);
        if(otp.join("").length === numerOfInputs) {
            console.log("otp.join", otp.join(""));
            handleLogin(otp);
        }
        const currentInput = otpInputRefs?.[currentIndex]?.current;
        if (!(currentInput && currentInput.value)) {
            return;
        }
        currentInput.value = otp[currentIndex];
        console.log("useEffect: currentIndex", currentIndex);

        const nextIndex = currentIndex < numerOfInputs - 1 ? currentIndex + 1 : currentIndex;
        const nextInput = otpInputRefs?.[nextIndex]?.current;
        console.log("nextInput", nextInput);
        if (nextInput) {
            nextInput.focus();
            nextInput.select();
        }

    }, [otp]);

    useEffect(() => {
        // focus the firs iput initially
        if (otpInputRefs?.[0]?.current) {
            otpInputRefs?.[0]?.current?.focus();
            otpInputRefs?.[0]?.current?.select();
        }
        console.log("useEffect: otpInputRefs", otpInputRefs);
        // add the event listener for keyup keyboard event
    }, []);

    return (
        <div className="flex flex-col items-center justify-center">
            <h2 className="font-bold flex items-center justify-center">
                Verify Your Account
            </h2>
            <h1 className="font-regular text-center text-sm lg:text-base">
                We have sent the one time pass code (OTP) to {loginResponse.email}
            </h1>
            <h1 className="font-regular text-center text-sm lg:text-base">
                <span className="font-bold">Enter the code below</span> to confirm your
                email address
            </h1>
            <div className="flex items-center justify-center mt-4">
                {otpInputRefs.map((ref, index) => (
                    <input
                        key={index}
                        ref={ref}
                        className="w-10 h-10 md:w-12 md:h-12 lg:w-16 lg:h-16 border border-neutral-400 rounded-lg shadow-2xl bg-neutral-200 mx-2 text-center text-base md:text-xl lg:text-2xl focus:outline-none focus:border-primary"
                        type="number"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e, index)}
                        onFocus={() => setCurrentIndex(index)}
                        onClick={(e) => {
                            setCurrentIndex(index);
                        }}
                        value={otp[index]}
                    />
                ))}
            </div>
            <h1 className="font-regular text-center text-sm lg:text-base mt-5">
                Code expires in:
                {/* <span className="font-bold text-primary">03:00</span> */}
                <CountdownTimer initialTime={300} onTimerExpire={handleTimerExpire}/>
            </h1>
            <div className="flex items-center justify-center w-1/3 mt-8">
                <button
                    className="bg-primary text-white font-bold py-1 px-6 w-full rounded-lg focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={() => handleLogin(otp)}
                >
                    {loading ? <CircularProgressWithLabel/> : "Verify"}
                </button>
            </div>
            <h1 className="font-regular text-center text-sm lg:text-base mt-5">
                Didn't get the code?
                <span
                    className="font-bold text-primary uppercase"
                    style={{cursor: "pointer"}}
                    onClick={login}
                >
          {" "}
                    RESEND CODE
        </span>
            </h1>
        </div>
    );
};

export default Otp;
