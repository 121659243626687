import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/images/left arrow.svg";
import minusIcon from "../../assets/images/remove.svg";
import plusIcon from "../../assets/images/add.svg";
import APIService from "../../network/APIService";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";

const Inprogress: FC = () => {

  return (
    <div className="graduation-background">
      <div className="graduation-inprogress">
          <h1>We are processing your video files</h1>
          <div>
              <p> &nbsp;  </p>
              <p> &nbsp;  </p>
              <p>
                  Thanks for checking in and congrats on your graduation! 🎓✨
                  </p>

                      <p> &nbsp;  </p>
                  <p>
                  We are working hard to deliver your videos by the end of the day.
                  We will email you your university address when it’s ready.
              </p>
              <p>
                  If you do not hear from us within 24 hours, please come back and
                  submit your request at&nbsp;
                  <a href="https://www.gradcut.com/feedback">www.gradcut.com/feedback</a> or email
                  &nbsp;<a href="mailto:support@gradcut.com">support@gradcut.com</a>
              </p>
              <p> &nbsp;  </p>
              <p>
                  P.S. This is our pilot project; your feedback is very important to us!
                  Gradcut Team

              </p>
          </div>
      </div>

    </div>
  );
};

export default Inprogress;
