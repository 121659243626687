import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/images/left arrow.svg";
import minusIcon from "../../assets/images/remove.svg";
import plusIcon from "../../assets/images/add.svg";
import APIService from "../../network/APIService";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";

interface PaymentPageProps {
  checkedCheckboxes: boolean[];
  setCheckedCheckboxes: React.Dispatch<React.SetStateAction<boolean[]>>;
  handleCheckboxChange: (index: number) => void;
  setPaid: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CardInfo {
  cardNumber: string;
  nameOnCard: string;
  expiry: string;
  cvc: string;
  country: string;
  postalCode: string;
}

const PaymentPage: FC<PaymentPageProps> = ({
  checkedCheckboxes,
  setCheckedCheckboxes,
  handleCheckboxChange,
  setPaid,
}) => {
  const [value, setValue] = useState<number>(1);
  const [totalValue, setTotalValue] = useState<number>(24.95);
  const [selectedRadio, setSelectedRadio] = useState<string>("default-radio-1");
  const [cardInfo, setCardInfo] = useState<CardInfo>({
    cardNumber: "",
    nameOnCard: "",
    expiry: "",
    cvc: "",
    country: "",
    postalCode: "",
  });
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleCounter = (action: "increment" | "decrement"): void => {
    setValue((prevValue) => {
      const newValue =
        action === "increment"
          ? prevValue < 3
            ? prevValue + 1
            : prevValue
          : action === "decrement"
          ? prevValue > 1
            ? prevValue - 1
            : prevValue
          : prevValue;

      setCheckedCheckboxes((prevCheckboxes) => {
        const updatedCheckboxes = [...prevCheckboxes];
        // Decrement
        if (action === "decrement") {
          // Count how many true values
          const trueCount = updatedCheckboxes.filter((value) => value).length;

          // If there are 2 or more true values, set the one with the highest index to false
          if (trueCount >= 2) {
            const lastIndex = updatedCheckboxes.lastIndexOf(true);
            updatedCheckboxes[lastIndex] = false;
            handleCheckboxChange(lastIndex);
          }
        } else if (action === "increment") {
          // Find the index of the first false value and set it to true
          const firstFalseIndex = updatedCheckboxes.indexOf(false);
          if (firstFalseIndex !== -1) {
            updatedCheckboxes[firstFalseIndex] = true;
            handleCheckboxChange(firstFalseIndex);
          }
        }

        return updatedCheckboxes;
      });

      //Re-calculate the total
      const calculatedTotal = calculateTotal(newValue);
      setTotalValue(calculatedTotal);

      return newValue;
    });
  };

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const selectedRadioValue = event.target.id;
    setSelectedRadio(selectedRadioValue);

    if (selectedRadioValue === "radio-2") {
      // If the second radio button is selected, set all values in checkedCheckboxes to true
      const updatedCheckboxes = Array(checkedCheckboxes.length).fill(true);
      setCheckedCheckboxes(updatedCheckboxes);
      // Update totalValue to 49.95
      setTotalValue(49.95);
      setValue(3);
    }
  };

  const handleGoBack = () => {
    navigate("/library");
  };

  const calculateTotal = (selectedItemsCount: number): number => {
    const pricePerClip = 24.95;
    return selectedItemsCount * pricePerClip;
  };

  const cardValidation = () => {
    if (
      cardInfo.cardNumber === "" ||
      cardInfo.nameOnCard === "" ||
      cardInfo.expiry === "" ||
      cardInfo.cvc === "" ||
      cardInfo.country === "" ||
      cardInfo.postalCode === ""
    ) {
      setError("Please fill in all the fields");
    } else if (cardInfo.cardNumber.length !== 16) {
      setError("Card number must be 16 digits");
    } else {
      handlePaid();
    }
  };

  const handlePaid = () => {
    handlePayment();
  };

  useEffect(() => {
    // Get the number of items selected in previous page
    const numberOfTrueValues = checkedCheckboxes.filter(Boolean).length;
    setValue(numberOfTrueValues);
    // Get the total
    const calculatedTotal = calculateTotal(numberOfTrueValues);
    setTotalValue(calculatedTotal);
  }, [checkedCheckboxes]);

  const handlePayment = async () => {
    const arr: any = [];
    checkedCheckboxes.forEach((isChecked, index) => {
      if (isChecked) {
        arr.push(`${index + 1}`);
      }
    });
    setLoading(true);
    try {
      const obj = {
        ids: arr,
      };
      const response = await APIService.payment(obj);
      if (response && response.status === 200) {
        setLoading(false);
        setPaid(true);
        navigate("/library");
      } else {
        // alert(response.response.data);
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div>
      <h1 className="flex items-center justify-center m-4 p-2 font-bold text-base text-center lg:text-2xl">
        View your Graduation Videos
      </h1>
      <div className="flex items-center justify-start mx-2 lg:mx-10">
        <button
          className="flex gap-2 bg-white text-primary text-base font-bold py-1 px-6 rounded-lg focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleGoBack}
        >
          <img src={backIcon} alt="back icon" />
          Back
        </button>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-20 mx-10">
        {/* Order Summary */}
        <div className="flex flex-col items-center justify-center">
          <h2 className="font-bold flex items-center justify-center pb-4">
            Order Summary
          </h2>
          <div className="w-fit lg:w-96 px-4 bg-white rounded-lg shadow-2xl flex flex-col justify-center gap-4">
            <h3 className="font-bold pt-2 justify-start">Payment Options</h3>
            <div className="grid grid-cols-5 gap-4">
              <div className="flex items-center justify-center">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="radio-group"
                  className="w-4 h-4 text-primary bg-gray-100 border-gray-300 accent-primary"
                  checked={selectedRadio === "default-radio-1"}
                  onChange={handleRadioChange}
                />
              </div>
              <div className="flex items-center justify-center">
                <label
                  htmlFor="default-radio-1"
                  className="ms-2 text-sm font-medium text-gray-900"
                >
                  $24.95
                </label>
              </div>
              <div className="flex items-center justify-center">
                <button
                  className="bg-ash shadow-2xl"
                  onClick={() => handleCounter("decrement")}
                  disabled={value === 1}
                >
                  <img src={minusIcon} alt="minus icon" />
                </button>
                <span className="w-8 text-center">{value}</span>
                <button
                  className="bg-ash shadow-2xl"
                  onClick={() => handleCounter("increment")}
                  disabled={value === 3}
                >
                  <img src={plusIcon} alt="plus icon" />
                </button>
              </div>
              <div className="flex items-center justify-center">
                <span>per clip</span>
              </div>
            </div>
            <div className="grid grid-cols-5 gap-4">
              <div className="flex items-center justify-center">
                <input
                  id="radio-2"
                  type="radio"
                  value=""
                  name="radio-group"
                  className="w-4 h-4 text-primary bg-gray-100 border-gray-300 accent-primary"
                  checked={selectedRadio === "radio-2"}
                  onChange={handleRadioChange}
                />
              </div>
              <div className="flex items-center justify-center">
                <label
                  htmlFor="radio-2"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  $49.95
                </label>
              </div>
              <div className="flex items-center justify-center">
                <span className="w-8 text-center">3</span>
              </div>
              <div className="col-span-2 flex items-center justify-end">
                <span className="text-white text-xs bg-primary rounded-md p-1">
                  Save 33%
                </span>
              </div>
            </div>
            <div className="w-full flex items-center justify-center gap-4 mb-4 px-8 font-bold border border-b-0 border-r-0 border-l-0 border-t-ash">
              <h3>Total</h3>
              <h3>
                $ <span>{value === 3 ? "49.95" : totalValue.toFixed(2)}</span>
              </h3>
              <h3>AUD</h3>
            </div>
          </div>
          {/* Table titles */}
          <div className="grid grid-cols-4 w-screen lg:w-3/4 gap-4 mt-10 px-6 pb-2 border border-t-0 border-r-0 border-l-0 border-b-ash">
            <p className="text-sm font-medium text-black flex items-center justify-center">
              Description
            </p>
            <p className="text-sm font-medium text-black flex items-center justify-center">
              Rate
            </p>
            <p className="text-sm font-medium text-black flex items-center justify-center">
              Type
            </p>
            <p className="text-sm font-medium text-black flex items-center justify-center">
              Price
            </p>
          </div>
          {/* Table Body */}
          {checkedCheckboxes.map(
            (isChecked, index) =>
              isChecked && (
                <div
                  className="w-screen lg:w-3/4 grid grid-cols-4 gap-4 m-2 px-6 pb-2"
                  key={index}
                >
                  <p className="text-sm text-black flex items-center justify-center">
                    Graduation Video (without watermark)
                  </p>
                  <p className="text-sm text-black flex items-center justify-center">
                    per clip
                  </p>
                  {/* <p className="text-sm text-black flex items-center justify-center">$24.95</p> */}
                  <p className="text-sm text-black flex items-center justify-center">
                    {index === 0
                      ? "Horizontal"
                      : index === 1
                      ? "Square"
                      : "Vertical"}
                  </p>
                  <p className="text-sm text-black flex items-center justify-center">
                    $24.95
                  </p>
                </div>
              )
          )}

          <div className="w-screen lg:w-3/4 grid grid-cols-5 gap-4 m-2 px-6 pb-2">
            <div className="col-start-3 col-end-6 flex items-center justify-end gap-4 pt-2 font-bold border border-b-0 border-r-0 border-l-0 border-t-ash">
              <h3>Total:</h3>
              <h3>
                $ <span>{value === 3 ? "49.95" : totalValue.toFixed(2)}</span>
              </h3>
              <h3>AUD</h3>
            </div>
          </div>
        </div>
        {/* Payment Details */}
        <div>
          <h2 className="font-bold flex items-center justify-center">
            Payment Details
          </h2>
          <div className="bg-gray-50 px-4 lg:mt-0">
            {/* Card Details */}
            <div>
              <div className="mt-6 text-base leading-4 text-gray-800">
                {error && <div style={{ color: "red" }}>{error}</div>}
                <label className="text-primary text-xs font-bold mb-2">
                  Card Number
                </label>
                <input
                  className="border rounded border-ash p-2 mt-2 w-full text-base leading-4 placeholder-ash"
                  type="number"
                  placeholder="1234 1234 1234 1234"
                  value={cardInfo.cardNumber}
                  onChange={(e) => {
                    setError("");
                    setCardInfo({ ...cardInfo, cardNumber: e.target.value });
                  }}
                />
              </div>
              <div className="mt-2 text-base leading-4 text-gray-800">
                <label className="text-primary text-xs font-bold mb-2">
                  Name on Card
                </label>
                <input
                  className="border rounded border-ash p-2 mt-2 w-full text-base leading-4 placeholder-ash"
                  type="text"
                  value={cardInfo.nameOnCard}
                  onChange={(e) => {
                    setError("");
                    setCardInfo({ ...cardInfo, nameOnCard: e.target.value });
                  }}
                />
              </div>
              <div className="flex items-center justify-center gap-5">
                <div className="mt-2 text-base leading-4 text-gray-800">
                  <label className="text-primary text-xs font-bold mb-2">
                    Expiry
                  </label>
                  <input
                    className="border rounded border-ash p-2 mt-2 w-full text-base leading-4 placeholder-ash"
                    type="text"
                    placeholder="MM/YY"
                    value={cardInfo.expiry}
                    onChange={(e) => {
                      setError("");
                      setCardInfo({ ...cardInfo, expiry: e.target.value });
                    }}
                  />
                </div>
                <div className="mt-2 text-base leading-4 text-gray-800">
                  <label className="text-primary text-xs font-bold mb-2">
                    CVC
                  </label>
                  <input
                    className="border rounded border-ash p-2 mt-2 w-full text-base leading-4 placeholder-ash"
                    type="number"
                    value={cardInfo.cvc}
                    onChange={(e) => {
                      setError("");
                      setCardInfo({ ...cardInfo, cvc: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center gap-5">
                <div className="mt-2 text-base leading-4 text-gray-800">
                  <label className="text-primary text-xs font-bold mb-2">
                    Country
                  </label>
                  <input
                    className="border rounded border-ash p-2 mt-2 w-full text-base leading-4 placeholder-ash"
                    type="text"
                    placeholder="Australia"
                    value={cardInfo.country}
                    onChange={(e) => {
                      setError("");
                      setCardInfo({ ...cardInfo, country: e.target.value });
                    }}
                  />
                </div>
                <div className="mt-2 text-base leading-4 text-gray-800">
                  <label className="text-primary text-xs font-bold mb-2">
                    Postal Code
                  </label>
                  <input
                    className="border rounded border-ash p-2 mt-2 w-full text-base leading-4 placeholder-ash"
                    type="number"
                    value={cardInfo.postalCode}
                    onChange={(e) => {
                      setError("");
                      setCardInfo({ ...cardInfo, postalCode: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="py-8 px-4 flex items-center justify-center ">
              <button
                className="w-full bg-primary text-white justify-center inline-flex items-center py-1 px-6 text-sm font-medium text-center rounded-2xl"
                onClick={cardValidation}
              >
                {loading ? <CircularProgressWithLabel /> : "Purchase"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
